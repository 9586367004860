.toolbar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.buttons-vertical {
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.buttons-vertical-top {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.fg {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-rows: min-content;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  formly-field {
    grid-column: span 1;
    // &.xs {
    //   width: 50%;
    // }
    @include breakpoint(lg, min) {
      &.fi-xsmall {
        grid-column: span 1;
      }
      &.fi-small {
        grid-column: span 2;
      }
      &.fi-normal {
        grid-column: span 3;
      }
      &.fi-large {
        grid-column: span 4;
      }
      &.fi-xlarge {
        grid-column: span 5;
      }
    }
    &.fi-xxlarge {
      grid-column: 1/-1;
    }
  }
}

[class*='fi-'] {
  padding-left: 0px;
  //padding-right: 14px;
  //padding-bottom: 4px;
}

formly-field-nz-checkbox {
  margin-top: 36px;
}

formly-field-nz-input {
  nz-input-number {
    width: 100% !important;
  }
}

formly-field {
  om-input-number, om-select {
    width: 100% !important;
  }
}

.fi-title {
  background-color: #ededed;
  padding: 4px;
  border-radius: 6px;
  font-size: medium;
  font-weight: 600;
  margin-bottom: 8px;
}

.fi-hide {
  display: none;
}

.form-section-header {
  background-color: #eee;
  padding: 1px 8px;
  margin: 2px 15px 10px 0px;
  border-radius: 4px;
  color: #444;
  font-weight: bold;
  font-size: 10pt;
}

.debug {
  border: 1px solid blue;
  background-color: rgb(0, 115, 255);
}

